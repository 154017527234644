 .accordion-button {
   padding: 2px;
 }

 .accordion-item {
  padding: 2px;
}

.gateway-image-column {
  margin-left: auto;
  margin-right: auto;
  width: 60px;
}

.gateway-text {
  font-size: small;
  text-align: center;
  margin-bottom: 0px;
}

.gateway-status-icons {
  padding-left: 0px;
}

.align-items-center {
  margin: 0px;
  text-align: center;
}

.accordion button::after {
  border: none;
  display: none;
  width: 0px;
  padding: 0px;
}
