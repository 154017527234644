.sbFooter {
  text-align: center;
  line-height: 50px;
  background-color: #ece7be;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

a {
  color: #74aa43;
}

h5 {
  font-size: small;
}
