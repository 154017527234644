.App {
  text-align: center;
}

.btn-primary {
  background-color: #74aa43;
  border-color: #74aa43;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background: #588033;
  border-color: #588033;
}


.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background: #588033;
  border-color: #588033;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #588033;
  border-color: #588033;
}

.btn-danger {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
}

.show > .btn-primary.dropdown-toggle:focus {
  background: #588033;
  border-color: #588033;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 600px) {
  .card-header {
    text-align: center;
  }
  
  .date-picker .DateInput_input {
    font-size: 1em;
    padding: 0px;
    margin: 0px;
  }

  .device-text {
    /* margin-right: 10px; */
    /* margin-left: auto; */
    vertical-align: top;
    text-align: left;

    /* display: none; */
  }

  .top-buttons {
    width: calc(100% - 5px);
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
  }


}

@media screen and (max-width: 810px) {
  .device-image-cell {
    width: 135px;
    margin-left: auto;
    margin-right: auto;
  }
}

body {
  font-family: "Roboto","Helvetica","Arial", sans-serif;
}


.manage-network-modal {
  text-align: left;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.fade.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-title {
  text-align: left;
}

.btn-success {
  background-color: #74aa43;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-link {
  background-color: none;
  border: none;
}

body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  max-width: 960px;
  margin: auto;
  background-color: darkgray;

}

.table-header {
  text-align: center;
  color: #74aa43;
}

.input-label {
  text-align: left;
  color: #74aa43;
}

.container-fluid {
  padding: 2px;
}

.btn {
  margin: 2px;
}
/*
  Configurations for displaying table data
*/

.sort-header {
  cursor: pointer;
}

.device-table {
  border-collapse: collapse;
  margin: 2;
  padding: 2;
  width: 100%;
  table-layout: fixed;
}

.device-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.device-table .device-table-tr {
  border: 1px solid #ddd;
  padding: 5px;
}

.device-table .device-table-th,
.device-table .device-table-td td {
  text-align: left;
}

.device-table .device-table-tr .notification-sensor {
  text-align: left;
}

.device-table .device-table-th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.accounts-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

.accounts-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.accounts-table .accounts-table-tr {
  border: 1px solid #ddd;
  padding: 0.35em;
}

.accounts-table .accounts-table-th,
.accounts-table .accounts-tabld-td td {
  padding: 0px;
  text-align: left;
}

.accounts-table .accounts-table-th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  width: 33%;
}

.accounts-table-td {
  width: 33%;
}



.modal-header .close {
  width: 10%;
}

@media screen and (max-width: 600px) {
  .device-table {
    border: 0;
    min-width: 0px;
  }
  
  .sensor-image {
    /* margin-right: 0px; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    max-height: 55px;
    /* max-width: auto; */

  }



  .device-table .device-table-tr .device-table-td td,
  .accounts-table .accounts-table-tr .accounts-table-td td {
    text-align: center;
  }

  .device-table caption,
  .accounts-table caption {
    font-size: 1.3em;
  }

  .device-table .device-table-thead,
  .accounts-table .accounts-table-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .device-table .device-table-tr .device-table-td #device-text,
  .accounts-table .accounts-table-tr .accounts.table-td #device-text {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  .device-table .device-table-td td,
  .accounts-table .accounts-table-td td {
    border-bottom: 1px solid #ddd;
    font-size: 1em;
    text-align: left;
    width: 100%;
  }

  .sensor-data-table-wrapper,
  .gateway-data-table-wrapper {
    width: 100%;
    overflow: auto;
  }

  .device-table .device-table-td td.sensor-data-row,
  .device-table .device-table-td td.gateway-data-row {
    display: table-cell;
    width: auto;
    border-bottom: none;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
  }
  .device-table .device-table-td td.sensor-data-row::before,
  .device-table .device-table-td td.gateway-data-row::before {
    content: "";
  }

  .device-table.sensor-data-table {
    width: 200%;
  }

  .device-table.sensor-data-table thead tr,
  .device-table.gateway-data-table thead tr {
    display: flex;
    padding: 0;
  }
  .device-table.sensor-data-table tr,
  .device-table.gateway-data-table tr {
    padding: 0;
  }

  .device-table.sensor-data-table thead tr th {
    width: 25%;
  }

  .device-table.gateway-data-table thead tr th:first-child {
    width: 28%;
  }

  .device-table.gateway-data-table thead tr th {
    width: 18%;
  }

  .device-table.gateway-data-table thead tr th span {
    display: none;
  }

  .device-table.gateway-data-table thead tr th::before {
    content: attr(data-label-small);
  }

  .device-table .device-table-td td.gateway-data-row:first-child {
    width: 28%;
  }

  .device-table .device-table-td td.gateway-data-row {
    width: 18%;
  }

  .device-table .device-table-td::before,
  .accounts-table .accounts-table-td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .device-table .device-table-td:last-child,
  .accounts-table .accounts-table-td:last-child {
    border-bottom: 0;
  }
}

.black-link-color {
  color: black;
}

input.dropdown-search {
  border: none;
}