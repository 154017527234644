@media screen and (max-width: 600px){
  .sensor-charts {
    height: 400px;
    padding-left: 0px;
    padding-right: 4px;
    position: relative;
  }
}

.sensor-charts {
  height: 400px;
  padding-left: 0px;
  padding-right: 4px;
  position: relative;
}