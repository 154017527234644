.company-logo-image {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 70px;
}

.sbHeader {
  background-color: #ece7be;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 5px;
}

.signInHeader {
  background-color: #ece7be;
  padding-top: 5px;
}