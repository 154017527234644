.sensor-status-icons {
  padding-left: 0px;
}

.sensor-image-column {
  width: 60px;
}

.sensor-reading-row {
  /* margin: auto; */
  padding-top: 10px;
}

.sensor-reading-col {
  text-align: center;
}

.align-items-center {
  margin: 0px;
  text-align: center;
  align-items: center;
}

.MuiButton-contained {
  margin: 2px!important;
}

.accordion button::after {
  border: none;
  display: none;
  width: 0px;
  padding: 0px;
}

.h2 {
  font-weight: bold!important;
}

