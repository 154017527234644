@media screen and (max-width: 800px) {
  .account-user-card {
    min-width: 100%!important;
    padding: 1px;
  }
}

.account-user-card {
  min-width: 50%;
  max-width: 50%;
  padding: 1px;
  min-height: 100px;
  margin: 0px;
}

.MuiButton-contained {
  margin: 2px;
}

.MuiCardContent-root {
  padding: 5px!important;
  padding-left: 8px!important;
}